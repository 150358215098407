import{ useCallback, useEffect, useMemo, useState } from 'react'
import { timeSince, timeToNextUpdate } from '../../helpers/time-format'

// A little hook that returns a rough estimate of time elapsed since date-time. 
export function useTimeSince(dateInput: Date | string | number): string {
	const date = useMemo(() => new Date(dateInput), [dateInput])
	const dateString = date.toISOString()
	const [timeSinceString, setTimeSinceString] = useState<string>(timeSince(dateString))


	const timeSinceFormatter = useCallback(() => {
		return timeSince(dateString)
	}, [dateString])

	useEffect(() => {
		if (dateInput) {
			const ttnu = timeToNextUpdate(date.getTime())
			const timerId = setInterval(() => setTimeSinceString(timeSinceFormatter()), ttnu > 1000 ? ttnu : 1000)

			setTimeSinceString(timeSinceFormatter())
			return () => { clearInterval(timerId) }
		}
		setTimeSinceString(timeSinceFormatter())
	}, [date, dateInput, timeSinceFormatter])


	return timeSinceString
}