import React, { FC } from 'react'
import { IScrobbleDTO } from '../../features/scrobble/scrobbleInterfaces'
import AlbumCover from './AlbumCover'
import SongProgress from './SongProgress'

interface ICurrentlyPlayingProps {
	scrobble: IScrobbleDTO
}

const CurrentlyPlaying: FC<ICurrentlyPlayingProps> = ({ scrobble }) => {
	return (
		<>
			<h3>{scrobble.artists.map(a => a.name).join(', ')} - {scrobble.song_name}</h3>
			<AlbumCover scrobble={scrobble} />
			<SongProgress scrobble={scrobble} />
		</>
	)
}

export default CurrentlyPlaying
