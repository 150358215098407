/* eslint-disable jsx-a11y/no-distracting-elements */
import React from 'react'
import underConstruction from './under-construction.gif'
import visitCounter from './visit-counter.png'
import netscape from './netscape.gif'
import './App.css'
import { SongList } from './features/scrobble/SongList'
import { useDispatch } from 'react-redux'
import { addMockScrobble } from './features/scrobble/scrobbleSlice'


function App() {
	const dispatch = useDispatch()
	return (
		<div className="App">
			<header className="App-header">
				<h1 onClick={() => dispatch(addMockScrobble())}>Welcome to my modern scrobble page!</h1>
				<img src={underConstruction} alt="Under construction" width='600px' />
				{/* In production SongList should not be in "header". The CSS should be copied/moved so it can reside inside a content section.*/}
				<SongList></SongList>
			</header>
			<div className="Visitors">
				<span>You are visitor number </span>
				<img src={visitCounter} alt="Visit counter 1337"></img>
			</div>
			<div className="MadeFor">
				<span>Made for Netscape Navigator  </span>
				<img src={netscape} alt="Netscape Navigator"></img>
			</div>
			<h3>Gustav Öst © 1997</h3>
		</div>
	)
}

export default App
