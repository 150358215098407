import React, { FC } from 'react'
import { IScrobbleDTO } from '../../features/scrobble/scrobbleInterfaces'
import { useTimeSince } from '../../helpers/hooks/useTimeSince'
import './Song.css'

interface ISongPlayedCellProps {
	scrobble: IScrobbleDTO
}

const SongPlayedCell: FC<ISongPlayedCellProps> = ({ scrobble }) => {
	const started = new Date(scrobble.created_at).getTime()
	// Seems there is a crossfade or similar of about 9s
	const finishedPlaying = started + scrobble.duration_ms - 9000
	const timeSincePlayed = useTimeSince(finishedPlaying)
	return (
		<td className="played__time">{timeSincePlayed}</td>
	)
}

export default SongPlayedCell

