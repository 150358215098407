import React, { FC } from 'react'
import { IScrobbleDTO } from '../../features/scrobble/scrobbleInterfaces'
import ModemImage from '../modem-img/ModemImage'

interface IAlbumCoverProps {
	scrobble: IScrobbleDTO
}

const AlbumCover: FC<IAlbumCoverProps> = ({ scrobble }) => {
	return (
		<ModemImage scrobble={scrobble} height={200}></ModemImage>
	)
}

export default AlbumCover
