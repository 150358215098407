import React, { FC } from 'react'
import { IScrobbleDTO } from '../../features/scrobble/scrobbleInterfaces'
import ModemImage from '../modem-img/ModemImage'
import './Song.css'

interface IAlbumCoverProps {
	scrobble: IScrobbleDTO
}

const AlbumCoverCell: FC<IAlbumCoverProps> = ({ scrobble }) => {
	return (
		<td className="played__cover">
		<ModemImage scrobble={scrobble} height={64}></ModemImage>
		</td>
	)
}

export default AlbumCoverCell
