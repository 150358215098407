import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import scrobbleReducer from '../features/scrobble/scrobbleSlice'

export const store = configureStore({
	reducer: {
		scrobble: scrobbleReducer,
	}
})

export type IState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, IState, unknown, Action<string>>
