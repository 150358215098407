import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from '../../app/store'
import { IScrobbleDTO } from './scrobbleInterfaces'
import ScrobbleAPI, { mockScrobble } from '../../helpers/scrobble-api'

interface ScrobbleState {
	lastFetched: string | null
	items: IScrobbleDTO[]
	throttledItems: IScrobbleDTO[]
}

const initialState: ScrobbleState = {
	lastFetched: null,
	items: [],
	throttledItems: [] // For the 90's modem feel.
}

export const scrobbleSlice = createSlice({
	name: 'scrobble',
	initialState,
	reducers: {
		addScrobble: (state, action: PayloadAction<IScrobbleDTO>) => {
			state.throttledItems.unshift(action.payload)
		},
		receivedScrobbles: (state, action: PayloadAction<IScrobbleDTO[]>) => {
			state.lastFetched = new Date().toISOString()
			state.items = action.payload
		}
	}
})

export const { addScrobble, receivedScrobbles } = scrobbleSlice.actions

const DEFAULT_ZONE_ID = 'U291bmRab25lLCwxa21ubGNxZ3BvZy9Mb2NhdGlvbiwsMWp2bnk3aTdoMWMvQWNjb3VudCwsMW5kbWR6bmF5Z3cv'
const scrobbleApi = new ScrobbleAPI(DEFAULT_ZONE_ID)

export const ensureScrobbles = (): AppThunk => (dispatch, getState) => {
	const state = getState()

	if (state.scrobble.lastFetched !== null) return

	// Get history once.
	dispatch(fetchScrobbles())

	// Handle new scrobbles
	scrobbleApi.subscribe((scrobble: IScrobbleDTO) => dispatch(addScrobble(scrobble)))

}

const medianDelay = 1e4
const avrageScrobbleSeparation = 2e3

export const fetchScrobbles = (): AppThunk => (dispatch) => {
	scrobbleApi.fetchHistory()
		.then((scrobbles: IScrobbleDTO[]) => {
			// Populate "items" immediately 
			dispatch(receivedScrobbles(scrobbles))
			// Hang on there buckaroo! We're talking modem speeds here!
			// Modems are slow and downloads items out of order.
			scrobbles.forEach((scrobble, index) => {
				const randomDelay = (Math.random() * medianDelay) - medianDelay / 2
				setTimeout(
					() => dispatch(addScrobble(scrobble)),
					Math.max(avrageScrobbleSeparation, (scrobbles.length - index) * avrageScrobbleSeparation + randomDelay + 2000)
				)
			})
		})
		.catch((reason) => alert("Couldn't get scrobbles: " + reason))
}

export const addMockScrobble = (): AppThunk => (dispatch) => {
	dispatch(addScrobble(mockScrobble() as IScrobbleDTO))
}

export default scrobbleSlice.reducer