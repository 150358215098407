/**
 * Returns a string that describes how long ago "date" was.
 * @param date Return string will roughly desicribe time since this date
 * @returns 
 */
export function timeSince(date: string | Date) {

	const viewedDate = new Date(date)

	const seconds = Math.floor((new Date().getTime() - viewedDate.getTime()) / 1000)

	let interval = Math.floor(seconds / 31536000)

	if (interval > 1) {
		return viewedDate.toLocaleString()
	}

	interval = Math.floor(seconds / 86400)
	if (interval >= 1) {
		return `${interval} days ago`
	}
	interval = Math.floor(seconds / 3600)
	if (interval === 1) return `${interval} hour ago`
	if (interval >= 1) return `${interval} hours ago`

	interval = Math.floor(seconds / 60)
	if (interval === 1) return `${interval} minute ago`
	if (interval >= 1) return `${interval} minutes ago`
	if (seconds < 1){
		return `Just now`
	}
	return `${seconds} seconds ago`
}
/**
 * Returns ms until next update is to be done in a UI to show proper time
 * @param lastViewed Last timed viewed as milliseconds
 */
export const timeToNextUpdate = (lastViewed: number): number => {
	const timeSince = new Date().getTime() - lastViewed
	
	let interval = Number.MAX_SAFE_INTEGER / 2
	// Under a min
	if(timeSince < 6e4) interval = 1e3
	// Under an hour
	else if(timeSince < 36e5) interval = 6e4
	// Under a full day
	else if(timeSince < 36e5 * 24) interval = 36e5
	// More than a full day
	else interval = 36e5 * 24
	const nextUpdateMs = interval - (timeSince % interval)
	//console.log(nextUpdateMs)
	return nextUpdateMs
}