import React, { FC, useCallback, useEffect, useState } from 'react'
import { IScrobbleDTO } from '../../features/scrobble/scrobbleInterfaces'
import progressBarStyles from './CurrentlyPlaying.module.css'

interface ISongPlayedCellProps {
	scrobble: IScrobbleDTO
}

const SongProgress: FC<ISongPlayedCellProps> = ({ scrobble }) => {
	const [timePlayedString, setTimePlayedString] = useState("--:--")
	const [runtimeString, setRuntimeString] = useState("--:--")
	const [progressWidth, setProgressWidth] = useState<React.CSSProperties>({ width: '0%' })

	const formatTime = useCallback((timeMs) => {
		const min = Math.floor(timeMs / 6e4).toString().padStart(2, '0')
		const sec = Math.floor((timeMs / 1000) % 60).toString().padStart(2, '0')
		return `${min}:${sec}`
	}, [])

	const setProgress = useCallback(() => {
		const runtimeMs = new Date().getTime() - new Date(scrobble.created_at).getTime()
		setTimePlayedString(formatTime(runtimeMs))
		const percentage = Math.min(100, (runtimeMs / (scrobble.duration_ms - 9e3)) * 100)
		setProgressWidth({ transform: `translateX(${percentage - 100}%)` })
		return runtimeMs
	}, [formatTime, scrobble])
	
	const setInitialProgress = useCallback(() => {
		const runtimeMs = setProgress()
		setRuntimeString(formatTime(scrobble.duration_ms))
		const percentage = Math.min(100, (runtimeMs / (scrobble.duration_ms - 9e3)) * 100)
		setProgressWidth({ transition: 'none', transform: `translateX(${percentage - 100}%)` })
	}, [formatTime, scrobble, setProgress])

	useEffect(() => {
		// Avoiding CSS linear transition on initial load and song change.
		setInitialProgress()
		window.addEventListener('focus', setInitialProgress)
		const intervalId = setInterval(setProgress, 1000)
		return () => {
			window.removeEventListener('focus', setInitialProgress)
			clearInterval(intervalId)
		}
	}, [setInitialProgress, setProgress])

	return (
		<>
			<div className={progressBarStyles['progress-bar-wrapper']}><div className={progressBarStyles['progress']} style={progressWidth} /></div>
			<span>{timePlayedString} / {runtimeString}</span>
		</>
	)
}

export default SongProgress

