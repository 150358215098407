import React, { FC, useCallback, useEffect, useState } from 'react'
import { IScrobbleDTO } from '../../features/scrobble/scrobbleInterfaces'
import artworkStyles from '../Artwork.module.css'

interface IModemImageProps {
	scrobble: IScrobbleDTO
	height: number
}

// Component simulating the slow download of images when using a modem.
// Album art is square, height is sufficient.
const ModemImage: FC<IModemImageProps> = ({ scrobble, height }) => {

	const [albumArtFrameStyle] = useState<React.CSSProperties>({
		width: height,
		height: height,
	})

	const [loadedHeight, setLoadedHeight] = useState(0)
	const [croppingDivStyle, setCroppingDivStyle] = useState<React.CSSProperties>()
	const [imgStyle, setImgStyle] = useState<React.CSSProperties>()

	// Reset on new scrobble
	useEffect(() => {
		setLoadedHeight(0)
		setCroppingDivStyle({ transform: `translateY(-${height}px)`, height: height })
		setImgStyle({ transform: `translateY(${height}px)` })
	}, [height, scrobble])

	const updateLoadedHeight = useCallback((amount) => {
		if (loadedHeight < height) {
			const newHeight = Math.min(loadedHeight + amount, height)
			setLoadedHeight(newHeight)
			setCroppingDivStyle({ transform: `translateY(${newHeight - height}px)`, height: height })
			setImgStyle({ transform: `translateY(${height - newHeight}px)` })
		}
	}, [height, loadedHeight])

	useEffect(() => {
		const loadAmount = Math.random() * height * 0.1 // load between 0-10% per go.
		const loadDelay = Math.random() * 800 + 200 // load after 200-1000ms
		const timeoutId = setTimeout(() => updateLoadedHeight(loadAmount), loadDelay)
		return () => {
			clearTimeout(timeoutId)
		}
	}, [updateLoadedHeight, loadedHeight, height])

	return (
		<div style={albumArtFrameStyle} className={artworkStyles.albumArtFrame}>
			<div style={croppingDivStyle} className={artworkStyles.croppingAlbumArt}>
				<img
					style={imgStyle}
					height={height + 'px'}
					className={artworkStyles.cover}
					alt={scrobble.song_name + ' cover'}
					src={scrobble.image_url}
				/>
			</div>
		</div>
	)
}

export default ModemImage
