import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IState } from '../../app/store'
import SongRow from '../../components/song-row/SongRow'
import { ensureScrobbles } from './scrobbleSlice'
import './Scrobble.module.css'
import { IScrobbleDTO } from './scrobbleInterfaces'
import CurrentlyPlaying from '../../components/currently-playing/CurrentlyPlaying'
//import { timeToNextUpdate } from '../../helpers/time-format'
//import CurrentlyPlaying from '../../components/currently-playing/CurrentlyPlaying'

export const SongList: FC = () => {
	const dispatch = useDispatch()

	useEffect(() => { dispatch(ensureScrobbles()) }, [dispatch])

	const scrobbles = useSelector((state: IState) => state.scrobble.throttledItems)
	const [sortedScrobbles, setSortedScrobbles] = useState<IScrobbleDTO[]>([])
	const [nowPlaying, setNowPlaying] = useState<IScrobbleDTO>()

	useEffect(() => {
		// Since modems adds out-of-order, sort here.
		const sorted = [...scrobbles].sort((a, b) => a.created_at < b.created_at ? 1 : -1)

		const scrobble = sorted[0]
		if (scrobble) {
			const started = new Date(scrobble.created_at).getTime()
			const finishedPlaying = started + scrobble.duration_ms
			// Do we have a song currently playing?
			if (finishedPlaying > new Date().getTime())
				setNowPlaying(sorted.shift())
		}

		setSortedScrobbles(sorted)
	}, [scrobbles])

	return (
		<>
		{nowPlaying ? <CurrentlyPlaying scrobble={nowPlaying!} /> : <h3>No song playing</h3>}
			<table className="played">
				<thead>
					<tr>
						<th className="played__cover"></th>
						<th className="played__title">Title</th>
						<th className="played__artists">Artists</th>
						<th className="played__time">Played</th>
					</tr>
				</thead>
				<tbody>
					{sortedScrobbles.map(scrobble => (<SongRow key={scrobble.id} scrobble={scrobble} />))}
				</tbody>
			</table>
		</>
	)
}
