import React, { FC } from 'react'
import './Song.css'
import { IScrobbleDTO } from '../../features/scrobble/scrobbleInterfaces'
import AlbumCoverCell from './AlbumCoverCell'
import SongPlayedCell from './SongPlayedCell'

interface ISongProps {
	scrobble: IScrobbleDTO
}

const SongRow: FC<ISongProps> = ({ scrobble }) => {
	return (
		<tr className="played__row">
			<AlbumCoverCell scrobble={scrobble} />
			<td className="played__title">{scrobble.song_name}</td>
			<td className="played__artists">{scrobble.artists.map(a => a.name).join(', ')}</td>
			<SongPlayedCell scrobble={scrobble} />
		</tr>
	)
}

export default SongRow
